// w3-advanced-filter-active
& {
	&::before {
		content: "";
		transition: 0.5s;
	}
}

&.w3-advanced-filter-active {
	&::before {
		content: "";
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, 0.35);
	}
}

// paragraph
p {
	min-height: 1rem;
}

// headings
&.home {
	h2, .h2 {
		@include font-size($h1-font-size);
	}
}

h6 {
	font-weight: 700;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// container
.container-medium {
	@include media-breakpoint-up(md) {
		max-width: 750px !important;
	}
}

.container-large {
	@include media-breakpoint-up(lg) {
		max-width: 960px !important;
	}
}

.container-fluid {
	max-width: 1920px;

	@include media-breakpoint-up(xl) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// text-large
.text-large {
	font-size: 18px;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}
}

// link-large
.link-large {
	font-size: 18px;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}
}

// heading
.heading {
	max-width: 800px;
	margin: 0 auto 30px auto;
	text-align: center;

	p:last-of-type {
		margin-bottom: 0;
	}
}

.bg-light {
	.h1, .h2, .h3, .h4, .h5, .h6 {
		color: $text-dark;
	}
}

// default-card
.default-card {
	border-color: $gray;

	.card-tags {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		list-style: none;
		column-gap: 5px;
		row-gap: 5px;

		.card-tag {
			a {
				display: block;
				padding: 5px;
				background-color: $blue-light;
				border-radius: 6px;
				color: $black;
				font-size: 12px;
				text-decoration: none;

				&:hover {
					background-color: $blue;
					color: $white;
				}
			}
		}
	}
}

// accommodation-card
.accommodation-card {
	border-color: $gray;

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 20px;
		}

		.card-caption {
			.card-title {
				font-size: 20px;
				font-weight: 700;
			}

			.card-subtitle {
				display: none;
				font-size: 18px;
			}

			.card-description {
				.card-description-content {
					ul:not(.list-icons) {
						margin: 1rem 0;
						padding: 0;
						list-style: none;
						text-align: left;

						li {
							position: relative;
							margin-bottom: 2px;
							padding: 0 0 0 20px;
							color: $black;

							&::before {
								content: '\f14a';
								position: absolute;
								top: 3px;
								left: 0;
								color: $primary;
								font-size: 14px;
								font-weight: 900;
								font-family: $font-awesome;
							}
						}
					}

					.list-icons {
						display: flex;
						flex-flow: row wrap;
						align-items: center;
						margin-top: 1rem;

						.list-item {
							display: flex;
							align-items: center;
							gap: 5px;
							min-height: 20px;
							margin: 2px;
							padding: 2px 5px;
							background-color: $blue-light;
							border-radius: 3px;
							font-size: 12px;
							cursor: pointer;

							.icon {
								position: static !important;
								color: $blue;
							}

							.label {
								display: none;
							}

							&:hover {
								background-color: $black;
								color: $white;

								.icon {
									color: $white;
								}

								.label {
									display: block;
									position: absolute;
									left: 50%;
									transform: translateX(-50%);
									bottom: calc(100% + 5px);
									padding: 5px 15px;
									background-color: $black;
									border-radius: 6px;
									color: $white;
									white-space: nowrap;
									font-size: 12px;

									&::after {
										content: '';
										position: absolute;
										top: 100%;
										left: 0;
										right: 0;
										margin: 0 auto;
										width: 0;
										height: 0;
										border-left: 5px solid transparent;
										border-right: 5px solid transparent;
										border-top: 5px solid $black;
									}
								}
							}
						}
					}
				}
			}
		}

		.card-buttons {
			margin-top: 45px;
			text-align: center;

			.card-btn {
				@extend .btn-secondary;
				padding: 0.375rem 0.75rem;
			}
		}
	}
}

// global-destinations
div.global-destinations {
	ul {
		justify-content: center;

		li {
			margin: 5px;

			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 110px;
				height: 110px;
				padding: 10px;
				background-color: $blue-light;
				border-radius: 6px;
				color: $black;
				font-size: 14px;
				text-align: center;
				text-decoration: none;

				img {
					max-width: 48px;
					max-height: 48px;
					margin-top: auto;
					transition: 0.5s;
				}

				.list-item-title {
					margin-top: auto;
				}

				&:hover {
					background-color: $blue;
					color: $white;

					img {
						filter: brightness(0) invert(1);
					}
				}
			}
		}
	}
}

// tiles-collection
.tiles-collection {
	&.grid {
		.grid-items {
			margin: 0 -10px -20px -10px;

			.item {
				margin-bottom: 20px;
				padding: 0 10px;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(8),
				&:nth-child(9) {
					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}
				}

				.card {
					border: none;

					.card-image {
						height: 100%;

						img {
							height: 100%;
							object-fit: cover;
						}
					}

					.card-img-overlay {
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						top: auto;
						min-height: 40%;
						text-align: center;
						background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
						transition: 0.5s;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							background: rgba($black, 0);
							transition: 0.5s;
						}

						.card-caption {
							position: relative;
							flex: none;
							text-shadow: none;

							.card-title {
								font-size: 20px;
								font-weight: 700;

								&:hover {
									color: inherit;
								}
							}

							.card-subtitle {
								font-size: 18px;
							}
						}

						.card-buttons {
							position: relative;

							.card-btn {
								@extend .btn-secondary;
							}
						}

						.card-subtitle,
						.card-description,
						.card-buttons {
							display: none;
						}
					}

					&:hover {
						.card-img-overlay {
							min-height: 100%;
							justify-content: center;
							background: rgba($black, 0);

							&::before {
								background: rgba($black, 0.5);
							}

							.card-subtitle,
							.card-description,
							.card-buttons {
								display: block;
							}
						}
					}
				}
			}
		}
	}
}

// breadcrumb
.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style: none;

	.breadcrumb-item {
		color: $blue;
		font-size: 12px;

		+.breadcrumb-item {
			&::before {
				content: '\00BB';
				margin: 0 2.5px;
			}
		}
	}
}

// w3media-booking-receipt-widget
.w3media-booking-receipt-widget {
	height: 100%;
}

// faq
.faq-overview {
	.faq-category {
		border: 1px solid #e9ecef;

		.faq-title {
			h2 {
				transition: color 0.5s;
			}

			&:hover {
				h2 {
					color: $blue;
				}
			}
		}

		.faq-items {
			padding: 0 5px;

			.faq-item {
				.faq-question {
					h3 {
						transition: color 0.5s;
					}

					&:hover {
						h3 {
							color: $blue;
						}
					}
				}

				&.active {
					.faq-question {
						h3 {
							color: $blue;
						}
					}
				}

				.faq-answer {
					margin-bottom: 5px;
				}
			}
		}
	}
}

// search
&.search {
	.content-section {
		.searchbox {
			.search-icon {
				display: none;
			}
		}
		.search_alert {
			margin-top: 1rem;
		}
		.search_term {
			margin-top: 1rem;
		}
	}
}

// blog-post-detail
&.blog-post-detail {
	.bundle-detail-section {
		.image-collection {
			display: none;
		}
	}
}

// w3-daterangepicker {
& {
	.w3-vue-booking-wrapper .w3-daterangepicker .dp__main .dp__menu {
		// top: 60px !important;
		left: 0 !important;
		transform: translate(0) !important;
	}

	.w3-vue-booking-wrapper.w3-searchbook-widget {

		.w3-daterangepicker .dp__main .dp__menu {
			@include media-breakpoint-up(md) {
				left: auto !important;
				right: 0;
			}
		}
	}
}
