// footer
.footer {
	margin-top: 6vh;
	line-height: 1.5;

	// footer-newsletter
	.footer-newsletter {
		padding: 4vh 0;
		text-align: center;
	}

	// footer-outro
	.footer-outro {
		padding-bottom: 8vh;

		.container-two-columns {
			margin-bottom: 6vh;

			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(md) {
							@include make-col(7);
						}
					}

					&.two {
						@include media-breakpoint-up(md) {
							@include make-col(5);
						}
					}
				}
			}
		}

		// footer-link
		.footer-link {
			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					margin: 10px 10px 0 0;

					a {
						@extend .btn;
						background-color: $black;
						border-color: $black;
						color: $white;

						&:hover {
							background-color: $red !important;
							border-color: $red !important;
							color: $white !important;
						}

						&[href^="tel:"]::before {
							content: "\f3cd";
							margin-right: 7px;
							font-family: $font-awesome;
						}

						&[href^="mailto:"]::before {
							content: "\f0e0";
							margin-right: 7px;
							font-family: $font-awesome;
						}
					}
				}
			}
		}

		// footer-text
		.footer-text {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					a {
						text-decoration: none !important;

						&:hover {
							text-decoration: underline !important;
						}
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				li {
					margin-right: 15px;
					font-size: 24px;
					a {
						&:hover {
							color: $black !important;
						}
					}
				}
			}
		}

	}

	// footer-copyright
	.footer-copyright {
		padding: 25px 0;

		ul {
			display: flex;
			flex-wrap: wrap;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 30px;
				color: $blue;
				font-size: 14px;

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

	}

}
