.news-letter {
	display: none;
 
	&.page-block {
		margin-top: 0.5rem;

		& > form {
			display: flex;
			flex-direction: column;
			gap: 1em;
		}
	}

	&-row {
		display: flex;
		flex-direction: row;
		align-items: center;

		&-splitted {
			gap: 1em;
		}

		&-submit {
			.form-group > input {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			& > *[type="submit"] {
				padding: 0.75rem 2.25rem;
				color: $white;
				line-height: 1.4;
				font-weight: 700;
				border: none;
				border-radius: 6px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				background-color: $secondary;
				transition: all 0.5s ease;
				white-space: nowrap;

				&:hover {
					background-color: darken($secondary, 25%);
				}
			}
		}

		&-errors,
		&-messages {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 1em;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					padding: 0.5em 1em;
					border-radius: 6px;
				}
			}
		}

		&-errors {
			ul {
				li {
					background-color: darken($red, 25%);
				}
			}
		}

		&-messages {
			ul {
				li {
					background-color: #44bd32;
				}
			}
		}

		.form-group {
			width: 100%;
			margin-bottom: 0;

			input {
				height: auto;
				padding: 0.75rem;
			}
		}
	}
}

// mailchimp
#mc-embedded-subscribe-form {
	.mc-row {
		display: flex;
		align-items: flex-start;
		margin-bottom: 1em;
		gap: 1em;

		@include media-breakpoint-down(sm) {
			display: block;
		}
	}

	.mc-field-group {
		width: 100%;
		padding-bottom: 0;

		@include media-breakpoint-down(sm) {
			margin-bottom: 1em;
		}

		.form-control {
			height: auto;
			min-height: 50px;
			border-radius: 6px;

			&.email {
				@include media-breakpoint-up(md) {
					border-radius: 6px 0 0 6px;
				}
			}
		}

		div.mce_inline_error {
			background-color: unset !important;
			margin-bottom: 0 !important;
		}
	}

	.email-row {
		gap: 0;
	}

	.button {
		border-radius: 6px;
		margin: 0;
		min-height: 50px;
		width: 100%;
		background-color: $secondary;

		@include media-breakpoint-up(md) {
			border-radius: 0 6px 6px 0 !important;
			width: 150px;
		}

		&:hover {
			background-color: darken($secondary, 25%);
		}
	}

	div#mce-responses {
		padding: 0;
		width: 100%;
		margin: 0;
	}

	div.response {
		padding: .75rem 1.25rem;
		margin: 0;
		width: 100%;
		border-radius: 6px;
	}

	#mce-success-response {
		background-color: #d4edda;
		color: #155724;
	}

	#mce-error-response {
		background-color: #f8d7da;
		color: #721c24;
	}
}