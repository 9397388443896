// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(xl) {
		height: calc(100vh - 235px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	.owl-carousel {
		.item {
			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			}

			.owl-container {
				z-index: 1;
				position: relative;
				max-width: 960px;

				.owl-caption {
					.owl-title {
						margin-bottom: 30px;
						line-height: 1;
						font-family: $font-family-secondary;
						transform: rotate(-7deg);
						font-size: clamp(40px, 5vw, 90px);
					}

					.owl-description {
						max-width: 400px;
						margin: 0 auto;
					}
				}
			}
		}
	}

	// owl
	.owl-nav,
	.owl-dots {
		display: none;
	}

	// mini-sab-home
	.mini-sab-home {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.container-holder {
			height: 100%;

			.column {
				justify-content: center;

				@include media-breakpoint-down(xs) {
					align-items: center;
				}
			}
		}

		.mini-search-book {
			max-width: 355px;
			padding: 35px 30px 45px 30px;
			background: $white;
			border-radius: 8px;
			box-shadow: 12px 12px 24px rgba($black, 0.15);

			// heading
			.heading {
				margin-bottom: 35px;
				color: $black;
				line-height: 1;
				font-family: $font-family-secondary;
				transform: rotate(-6deg);
				@include font-size($h1-font-size);

				@include media-breakpoint-up(lg) {
					font-size: 36px;
				}
			}

			// override booking-widgets repo.
			.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters {
				flex-direction: column;
			}
			.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters .filter-element.filter-context-button,
			.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters .filter-element.filter-context-advanced_filter_button {
				flex: 0 0 100% !important;
  				max-width: 100% !important;
			}
			.w3-vue-booking-wrapper.w3-minisearchbook-widget .filters .filter-element.filter-context-advanced_filter_button .w3-popover-body {
				max-width: 295px !important;
				left: 5px !important;
			}
		}
	}
}

// search page
&.search {
	.eyecatcher,
	.eyecatcher .owl-carousel .item {
		@include media-breakpoint-down(md) {
			height: 50vh;
			min-height: 100px;
			max-height: 200px;
			background-position: center bottom;
		}
	}
}
