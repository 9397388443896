.collection.grid {
    .card {
        .card-body {
            .card-caption {
                .card-subtitle {
                    display: none;
                }
            }
        }
    }
}