// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		@include media-breakpoint-up(xl) {
			max-width: none;
		}

		.container-holder {
			z-index: 100;
			position: relative;
			border-radius: 8px;
			border: 1px solid $gray;
			background: $white;
			color: #fff;

			@include media-breakpoint-down(md) {
				margin: -115px 0 15px 0;
				padding: 15px 0;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 50px;
				padding: 15px 20px;
			}

			// override booking-widgets repo.
			.w3-vue-booking-wrapper.w3-minisearchbook-widget .filters .filter-element.filter-context-advanced_filter_button .w3-popover-body {
				@include media-breakpoint-down(lg) {
					max-width: 295px !important;
					left: 5px !important;
				}
			}
			.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters .filter-element.filter-context-button,
			.w3-vue-booking-wrapper.w3-minisearchbook-widget .widget-element .filters .filter-element.filter-context-advanced_filter_button {
				@include media-breakpoint-down(md) {
					flex: 0 0 100% !important;
  					max-width: 100% !important;
				}
			}
		}
	}
}
