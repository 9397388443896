// header
.header {
	z-index: 997;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background-color: $white;
	box-shadow: $shadow;
	transition: 0.5s;

	&.sticky {
		position: fixed;

		.header-bottom {
			padding: 6px 0;
		}
	}

	.header-top {
		padding: 6px 0;
		background-color: $blue;
		transition: 0.25s;

		.container-one-column {
			.column {
				justify-content: flex-end;
			}
		}
	}

	.header-bottom {
		padding: 10px 0;
		transition: 0.5s;

		@include media-breakpoint-up(xl) {
			padding: 20px 0;
		}
	}

	.container,
	.container-fluid {

		@include media-breakpoint-up(xl) {
			padding-left: 40px;
			padding-right: 40px;
		}

		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-left: auto;

		.navbar-toggler {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			padding: 0;
			border: none;
			color: $black;
			font-size: 16px;
			font-weight: 700;
			transition: color 0.5s;

			i {
				width: 30px;
				margin-left: 2px;
				font-size: 20px;
				line-height: 30px;
				text-align: center;
				text-transform: uppercase;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				color: $blue;
			}
		}
	}

	// logo
	.logo {
		transition: 0.5s;

		@include media-breakpoint-down(md) {
		}

		a {
			display: block;

			img {
				width: auto;
				height: 40px;
			}

			&:hover {
				opacity: 0.85;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;

			.navbar-nav {
				margin-top: 15px;
				border-bottom: 1px solid rgba($blue, 0.15);

				>.nav-item {
					border-top: 1px solid rgba($blue, 0.15);

					>.nav-link {
						display: flex;
						align-items: center;
						padding-top: 0.75rem;
						padding-bottom: 0.75rem;
						color: $black;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.dropdown-menu {
					position: static !important;
					transform: none !important;
					margin: 0 0 0 15px;
					padding: 0;
					border: none;
					box-shadow: none;
					background: transparent;

					.nav-item {
						.nav-link {
							color: $black;
							font-size: 16px;

							&:hover {
								color: $blue;
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}
						}

						&.active {
							.nav-link {
								color: $blue;
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin: 0 auto;

				>.nav-item {
					margin: 0 10px;

					>.nav-link {
						color: $black;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						border-radius: 6px;

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $blue;
								}
							}

							&.active {
								.nav-link {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}
	}

	// wishlist-info
	div.wishlist-info {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		margin-right: 10px;

		a {
			position: relative;
			display: block;
			color: $red;

			.icon {
				font-size: 24px;

				i {
					font-weight: 900;
				}
			}

			.badge {
				position: absolute;
				right: -8px;
				bottom: 0;
				width: 16px;
				height: 16px;
				//background-color: $blue-light;
				//border-radius: 100%;
				color: $blue;
				font-size: 9px;
				font-weight: 700;
				line-height: 16px;
				text-align: center;
				overflow: hidden;

				&:empty {
					display: none;
				}
			}
		}
	}

	.header-bottom {
		.searchbox {
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}

	// searchbox
	.searchbox {
		position: relative;
		min-width: 34px;
		height: 34px;
		margin-right: 10px;

		.form-control {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			width: 200px;
			transition: $transition-base;

			&.show {
				display: block;
			}
		}

		.search-icon {
			display: block;
			color: $blue;
			font-size: 24px;
			line-height: 34px;
			text-align: center;
			transition: $transition-base;
			cursor: pointer;

			i {
				font-weight: 400;
			}

			&.hide {
				display: none;
			}

			&:hover {
				color: $black;
			}
		}
	}

	.header-top {
		// searchbox
		.searchbox {
			@include media-breakpoint-up(xl) {
				display: none;
			}
			margin-right: 4px;
			.search-icon {
				font-size: 18px;
				color: $white;

				@include media-breakpoint-up(md) {
					font-size: 16px;
				}
			}

			.form-control {
				right: 8px;
			}
		}
	}

	// global-partners
	div.global-partners {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		ul {
			align-items: center;

			li {
				+li {
					margin-left: 10px;
				}

				.list-item-title {
					display: none;
				}
			}
		}
	}

	// global-contact
	div.global-contact {
		// margin-left: auto;

		ul {
			align-items: center;

			li {

				&:nth-child(1),
				&:nth-child(2) {
					@include media-breakpoint-down(lg) {
						.list-item-title {
							display: none;
						}
					}

					@include media-breakpoint-up(xl) {
						display: none;
					}
				}

				&:nth-child(3) {
					@include media-breakpoint-down(lg) {
						.list-item-title {
							display: none;
						}
					}

					@include media-breakpoint-up(xl) {
						i {
							display: none;
						}
					}
				}

				+li {
					margin-left: 15px;

					@include media-breakpoint-up(xl) {
						margin-left: 30px;
					}
				}

				font-size: 14px;

				a {
					color: $white;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}

					i {
						font-size: 18px;

						@include media-breakpoint-up(md) {
							font-size: 16px;
						}
					}

					.list-item-title {
						margin-left: 5px;

						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		@include media-breakpoint-up(lg) {
			margin-left: 30px;
		}

		.nav-link {
			&.dropdown-toggle {
				&::after {
					display: none;
				}
			}
		}
	}
}

&.accommodation-book {
	.header {
		position: relative;
	}
}
