// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$red: #d9534f;
$blue: #3498db;
$blue-light: #e0eff9;
$blue-dark: #1f74ae;
$gray: #b7b7b7;
$gray-light: #fcfcfc;
$gray-dark: #707070;

$white: #fff;
$black: #000;

// theme-colors
$primary: $blue;
$secondary: $red;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Satisfy&display=swap');
$font-family-primary: 'Lato', sans-serif;
$font-family-secondary: 'Satisfy', cursive;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-primary;
$headings-font-weight: 400;
$headings-color: $blue;

// contextual
$light: $blue;
$dark: $blue-dark;
$text-light: $white;
$text-dark: $white;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);
$border-radius: 6px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
