// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// usps-section
// =========================================================================
.usps-section {
	margin-top: 20px;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin: 0 -10px -10px -10px;

				.item {
					margin-bottom: 10px;
					padding: 0 10px;

					.card {
						flex-direction: row;
						background-color: $blue-light;
						border-color: $blue-light;

						&[href]:hover {
							background-color: $white;
							border-color: $blue-dark;
						}

						.card-image {
							display: flex;
							padding: 15px 0 15px 15px;

							img {
								width: 80px;
								height: 80px;
							}
						}

						.card-body {
							.card-caption {
								text-align: left;

								.card-title {
									margin-bottom: 0.375rem;
									color: $blue-dark;
									font-size: 20px;
								}

								.card-subtitle {
									margin-bottom: 0;
									font-size: 18px;
								}
							}
						}
					}
				}
			}
		}
	}

	// global-usps (usps_bar collection)
	/*div.global-usps {
		ul {
			justify-content: center;

			li {
				margin: 5px;
				font-size: 18px;

				@include media-breakpoint-up(xl) {
					margin: 5px 30px;
				}

				&::before {
					content: '\f14a';
					margin-right: 5px;
					color: $blue;
					font-weight: 700;
					font-family: $font-awesome;
				}

				a {
					color: $black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	*/
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	// text-align: center;

	// .container-one-column {
	// 	@include media-breakpoint-up(xl) {
	// 		max-width: 960px;
	// 	}
	// }

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

		// blog
		.countries {
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				li {
					margin: 5px;

					a {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 110px;
						height: 110px;
						padding: 10px;
						background-color: $blue-light;
						border-radius: 6px;
						color: $black;
						font-size: 14px;
						text-align: center;
						text-decoration: none;

						img {
							max-width: 48px;
							max-height: 48px;
							margin-top: auto;
							transition: 0.5s;
						}

						.list-item-title {
							margin-top: auto;
						}

						&:hover {
							background-color: $blue;
							color: $white;

							img {
								filter: brightness(0) invert(1);
							}
						}
					}
				}
			}
		}

		.tags {
			.list {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				.list-item {
					margin: 5px;

					.list-link {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
						padding: 10px;
						background-color: $blue-light;
						border-radius: 6px;
						color: $black;
						font-size: 14px;
						text-decoration: none;

						.list-link-icon {
							margin-right: 10px;

							img {
								max-width: 20px;
								max-height: 20px;
								transition: 0.5s;
							}
						}

						&:hover {
							background-color: $blue;
							color: $white;

							img {
								filter: brightness(0) invert(1);
							}
						}
					}
				}
			}
		}

		.tags {
			margin-bottom: 30px;
		}

	}
}

&.home {
	.lead-section {
		text-align: center;

		.container-one-column {
			@include media-breakpoint-up(xl) {
				max-width: 960px;
			}
		}
	}
}

&.accommodation-detail {
	.lead-section {
		margin-bottom: 45px;
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

&.accommodation-search-book {
	.content-section {
		margin-top: 30px;
	}
}

// banner-section
// =========================================================================
.banner-section {
	margin: 6vh 0;

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 360px;
		max-height: 720px;

		.item {
			height: 75vh !important; // overide
			min-height: 360px;
			max-height: 720px;
			padding: unset; // overide

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.3);
			}

			.owl-container {
				z-index: 1;
				position: relative;
				max-width: 960px;

				.owl-caption {
					.owl-title {
						margin-bottom: 30px;
						line-height: 1;
						font-family: $font-family-secondary;
						transform: rotate(-7deg);
						font-size: clamp(40px, 5vw, 70px);
					}

					.owl-description {
						max-width: 400px;
						margin: 0 auto;
					}
				}
			}
		}

		.owl-dots,
		.owl-nav {
			display: none;
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
	padding: 6vh 0;
	background-color: $blue-light;

	// collection
	.collection {
		.card {
			@extend .accommodation-card;
		}
	}
}

// outro-section
// =========================================================================
.outro-section {
	margin: 6vh 0;
	text-align: center;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&:not(.accommodation-overview) {
			.card {
				@extend .default-card;
			}
		}

		&.accommodation-overview {
			.card {
				@extend .accommodation-card;
			}
		}
	}

	// blog-filter
	.blog-filter {
		.list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.list-item {
				margin: 5px;

				.list-link {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					padding: 10px;
					background-color: $blue-light;
					border-radius: 6px;
					color: $black;
					font-size: 14px;
					text-decoration: none;

					.list-link-icon {
						margin-right: 10px;

						img {
							max-width: 20px;
							max-height: 20px;
							transition: 0.5s;
						}
					}

					&:hover {
						background-color: $blue;
						color: $white;

						img {
							filter: brightness(0) invert(1);
						}
					}
				}
			}
		}
	}


	.blog-country-filter {
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			li {
				margin: 5px;

				a {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 110px;
					height: 110px;
					padding: 10px;
					background-color: $blue-light;
					border-radius: 6px;
					color: $black;
					font-size: 14px;
					text-align: center;
					text-decoration: none;

					img {
						max-width: 48px;
						max-height: 48px;
						margin-top: auto;
						transition: 0.5s;
					}

					.list-item-title {
						margin-top: auto;
					}

					&:hover {
						background-color: $blue;
						color: $white;

						img {
							filter: brightness(0) invert(1);
						}
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 45px 0;

	.gallery-horizontal {
		.gallery-item {
			position: relative;

			.gallery-link {
				border-radius: 6px;
			}

			&.last-item {
				.gallery-link {
					.gallery-caption {
						display: inline-flex;
						align-items: flex-end !important;
						justify-content: flex-end !important;
						top: auto;
						min-height: 50%;
						padding: 15px 20px;
						font-size: 14px;
						line-height: 20px;
						background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);

						&::before {
							display: none;
						}

						&::after {
							content: '\f002';
							margin-left: 15px;
							font-size: 16px;
							font-family: $font-awesome;
						}
					}
				}
			}

			.wishlist-toggle {
				z-index: 10;
				position: absolute;
				top: 15px;
				left: 25px;
				color: white;

				&>i {
					font-size: 1.5rem;

					&.far {
						color: $white;
					}

					&.fas {
						color: $red;
					}
				}
			}
		}
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 45px 0;

	h2 {
		margin-bottom: 30px;
	}
}

// property-detail-section
// =========================================================================
.property-detail-section {
	margin: 45px 0;

	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-up(xl) {
						@include make-col(8);
					}

					padding-right: 15px !important;
				}

				&.two {
					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}

					padding-left: 15px !important;
				}
			}
		}
	}

	// usps
	.usps {
		margin-bottom: 30px;

		&:not(:empty) {
			display: none;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 3px;
			}
		}
	}

	// properties
	.properties {
		margin-bottom: 30px;

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: -5px;
			padding: 0;
			list-style: none;

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 110px;
				height: 110px;
				margin: 5px;
				padding: 10px;
				background-color: $blue-light;
				border-radius: 6px;
				color: $black;
				font-size: 14px;
				text-align: center;
				text-decoration: none;

				@include media-breakpoint-down(lg) {
					width: 100px;
					height: 100px;
				}

				.icon {
					margin: auto;
					color: $blue;
					font-size: 36px;
				}

				&:nth-child(n+7) {
					display: none;
				}
			}
		}
	}

	// characteristics-accordion
	.characteristics-accordion {
		.accordion {
			margin-top: 0 !important;

			.card {
				border-top: none !important;
				margin-bottom: 1px;
				border-radius: 6px !important;
				background-color: $blue-light;

				&:last-of-type {
					border-bottom: none !important;
				}

				.card-header {
					width: 100%;
					margin: 0 auto;
					padding: 0 !important;

					h2 {
						button {
							padding: 15px !important;
							color: $blue !important;
							font-size: 20px !important;
							font-style: normal !important;

							&::after {
								font-size: 24px !important;
							}

							&:hover {
								color: $black !important;

								&::after {
									color: $black;
								}
							}
						}
					}
				}

				.collapse {
					width: 100%;
					margin: 0 auto;
					padding: 0 15px 15px 15px !important;

					p {
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

// property-location-section
// =========================================================================
.property-location-section {
	margin: 45px 0;
	padding: 45px 0;
	background: $blue-light;

	iframe {
		margin-top: 30px;
		border-radius: 10px;
	}
}

// reviews-section
// =========================================================================
.reviews-section {

	// review-create
	.review-create {
		margin-bottom: 30px;
	}

	// review-overview-average
	.review-overview-average {
		margin-bottom: 30px;
	}

	// review-overview-list
	.review-overview-list {
		.row {
			margin-bottom: -15px;

			.col {
				@include make-col(12);

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				margin-bottom: 15px;

				.col-holder {
					padding: 15px;
					background-color: $white;
					box-shadow: 0 0 0.25rem rgba($black, 0.075);
					border-radius: 6px;
				}
			}
		}

		// review-overview-list-averages
		.review-overview-list-averages {
			@extend .list-unstyled;

			.average {
				display: flex;
				margin-bottom: 5px;

				.average-title {
					max-width: 160px;
				}

				.average-number {
					margin: 0 5px 0 auto;
				}

				.average-rating {
					color: $yellow;

					i {
						margin-left: 2px;
					}
				}
			}
		}

		// review-overview-list-comments
		.review-overview-list-comments {
			.review {
				margin-bottom: 15px;
			}
		}

	}
}
