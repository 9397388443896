$widget-123boeken-background-color: #f8f8f8;
$widget-123boeken-border-color: #e7e7e7;

[data-widget="123boeken"],
[data-widget="123boeken"] .widget-123boeken-booking {
	max-width: unset;
}

[data-widget="123boeken"] .widget-123boeken-booking .widget-123boeken-button .widget-123boeken-back {
	display: none;
}

[data-widget="123boeken"] .widget-123boeken-section-container .widget-123boeken-section-left {
	padding-left: 0;

	@include media-breakpoint-down(sm) {
		padding-right: 0;
	}
}

[data-widget="123boeken"] .widget-123boeken-options .widget-123boeken-option {
	display: flex;
	flex-direction: column;
}
[data-widget="123boeken"] .widget-123boeken-options .widget-123boeken-option .widget-123boeken-optiontitle {
	order: -2;
}
[data-widget="123boeken"] .widget-123boeken-options .widget-123boeken-option .widget-123boeken-row:last-of-type {
	order: -1;
}
[data-widget="123boeken"] .widget-123boeken-options .widget-123boeken-option .widget-123boeken-row > .widget-123boeken-description {
	width: 100%;
	margin-left: 0;
	font-size: 14px;
}



[data-widget="123boeken"] .widget-123boeken-object {
	display: none;
}

[data-widget="123boeken"] .widget-123boeken-persons,
[data-widget="123boeken"] .widget-123boeken-calendar>:nth-child(1),
[data-widget="123boeken"] .widget-123boeken-calendar>:nth-child(2) {
	background-color: $widget-123boeken-background-color;
	border: 1px solid $widget-123boeken-border-color;
	border-radius: 5px;
}

[data-widget="123boeken"] .widget-123boeken-persons .widget-123boeken-title {
	background: transparent;
}

[data-widget="123boeken"] .widget-123boeken-calendar>:nth-child(2) .widget-123boeken-quotation .widget-123boeken-table.widget-123boeken-pricelist .widget-123boeken-row:last-child {
	border-radius: 0 0 5px 5px;
}

[data-widget="123boeken"] .widget-123boeken-button button {
	@extend .btn;
	@extend .btn-secondary;
	text-transform: capitalize;
	width: 100%;
}

[data-widget="123boeken"] .widget-123boeken-calendar>:nth-child(2) .widget-123boeken-quotation .widget-123boeken-table,
[data-widget="123boeken"] .widget-123boeken-section-container .widget-123boeken-section-right .widget-123boeken-prices,
[data-widget="123boeken"] .widget-123boeken-section {
	border-radius: 5px;
}

[data-widget="123boeken"] .widget-123boeken-section {
	.widget-123boeken-header {
		border-radius: 5px 5px 0 0;
	}
}

// pika
[data-widget="123boeken"] .pika-single {
	color: $body-color;
	font-family: $font-family-base;
}

[data-widget="123boeken"] .pika-lendar {
	border-radius: 5px;
	background-color: $white;
}

[data-widget="123boeken"] .pika-table th {
	font-size: 14px;
}

[data-widget="123boeken"] .pika-button {
	font-size: 14px;
}

[data-widget="123boeken"] .pika-single td.is-startrange .pika-button {
	background-color: $blue-dark;
}

[data-widget="123boeken"] .pika-single td.is-inrange button.pika-button {
	background-color: $blue;
}

[data-widget="123boeken"] .pika-single td.is-endrange button.pika-button {
	background-color: $blue-dark;
}

[data-widget="123boeken"] .pika-single td.is-startday .pika-button,
[data-widget="123boeken"] .pika-single td.is-endday .pika-button {
	background-color: #43a538;
}

[data-widget="123boeken"] .pika-single td:not(.is-disabled):not(.is-startday):not(.is-endday):not(.is-startrange):not(.is-promotion):not(.is-booked):not(.is-option):not(.is-promotion):not(.is-selected):not(.is-dummy) .pika-button {
	background-color: lighten(#43a538, 50%);
}
